@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.woff2") format("woff2"), url("../fonts/montserrat.woff") format("woff"), url("../fonts/montserrat.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserratmedium.woff2") format("woff2"), url("../fonts/montserratmedium.woff") format("woff"), url("../fonts/montserratmedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserratbold.woff2") format("woff2"), url("../fonts/montserratbold.woff") format("woff"), url("../fonts/montserratbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  min-height: 100vh;
  font-size: 10px;
  font-weight: 400;
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.2;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:hover {
  color: inherit;
  text-decoration: none;
}
ol,
ul {
  list-style-position: inside;
}
.wrap {
  width: 100%;
  max-width: 920px;
  margin: 0 auto;
  padding: 0 10px;
}
header .header__mob {
  display: none;
  background-color: #0000FF;
  padding: 8px 15px;
  margin-bottom: 15px;
}
header .header__burger {
  position: relative;
  width: 25px;
  height: 16px;
}
header .header__burger span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all 0.3s ease;
}
header .header__burger span:nth-child(2) {
  top: 7px;
}
header .header__burger span:nth-child(3) {
  top: 14px;
}
header .header__title {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
}
header .header__banner {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}
header .header__banner img {
  width: 100%;
  height: auto;
}
main {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  min-height: calc(100vh - 164px);
}
article {
  flex: 1;
}
aside {
  width: 240px;
  margin: 0 20px 30px 0;
}
aside .sidebar-block {
  margin-bottom: 15px;
}
aside .sidebar-block__title {
  display: block;
  background-color: #0000FF;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  padding: 3px 5px;
  margin-bottom: 5px;
}
aside .sidebar-block__list {
  list-style: none;
}
aside .sidebar-block__list li {
  margin-bottom: 5px;
}
aside .sidebar-block__list li.current a {
  text-decoration: none;
}
aside .sidebar-block__list a {
  text-decoration: underline;
}
aside .sidebar-block__list a:hover {
  text-decoration: none;
}
.news-block {
  margin-bottom: 15px;
}
.news-block__caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  margin-bottom: 5px;
}
.news-block__title {
  font-size: 12px;
  text-decoration: underline;
  color: #0068B2;
}
.news-block__title:hover {
  color: #0068B2;
}
.content > * {
  margin-bottom: 12px;
}
.content__title,
.content h1 {
  position: relative;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  background-color: #0000FF;
  padding: 2px 5px;
}
.content__title span,
.content h1 span {
  position: relative;
  background-color: #0000FF;
  padding: 0 10px;
}
.home .content__title,
.home .content h1 {
  text-transform: uppercase;
}
.home .content__title:before,
.home .content h1:before {
  display: block;
  content: '';
  width: 94%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 3%;
  top: 50%;
  transform: translateY(-50%);
}
.content h2,
.content h3 {
  color: #0000FF;
  text-align: center;
}
.content h2 {
  font-size: 12px;
}
.content h3 {
  font-size: 10px;
}
.content a {
  color: #0068B2;
  text-decoration: underline;
}
.content a:visited,
.content a:hover {
  text-decoration: none;
}
.content ul,
.content ol {
  padding-left: 10px;
}
.content blockquote {
  background-color: #E5E5E5;
  border-radius: 30px;
  text-align: center;
  font-weight: 500;
  font-style: italic;
  padding: 11px 25px;
}
.content table {
  max-width: 100%;
  border-collapse: collapse;
  margin: 0 auto 12px;
}
.content table td {
  min-width: 65px;
  text-align: center;
  font-weight: 500;
  border: 1px solid #0000FF;
  padding: 3px 20px;
}
.content img.full {
  width: 100%;
}
.content img.left {
  float: left;
  margin-right: 15px;
}
.content img.right {
  float: right;
  margin-left: 15px;
}
.content img.center {
  display: block;
  max-width: 100%;
  margin: 0 auto 12px;
}
footer .footer__copyright {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  padding: 10px;
  background-color: #0000FF;
}
@media screen and (max-width: 768px) {
  header.wrap,
  footer.wrap {
    padding: 0;
  }
  header .header__mob {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  header .header__burger.active span:nth-child(1) {
    transform: rotate(33deg);
    transform-origin: left;
  }
  header .header__burger.active span:nth-child(2) {
    width: 0;
  }
  header .header__burger.active span:nth-child(3) {
    transform: rotate(-33deg);
    transform-origin: left;
  }
  header .header__banner {
    display: none;
  }
  main {
    flex-direction: column-reverse;
  }
  aside {
    width: 100%;
    margin: 0 0 20px;
    padding: 0;
  }
  aside .sidebar-block__list li {
    text-align: center;
  }
  .content__title,
  .content h1 {
    background-color: transparent;
    color: #0000FF;
  }
  .content__title span,
  .content h1 span {
    background-color: transparent;
  }
  .home .content__title:before,
  .home .content h1:before {
    display: none;
  }
  .site-nav {
    position: absolute;
    top: -20px;
    left: 45px;
    background-color: #0000FF;
    padding: 5px;
    max-width: 150px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.56);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease;
  }
  .site-nav.active {
    top: 10px;
    opacity: 1;
    visibility: visible;
  }
  .site-nav .sidebar-block__title {
    display: none;
  }
  .site-nav ul {
    flex-direction: column;
    color: #fff;
  }
  .site-nav ul li {
    margin-bottom: 10px;
    text-align: left;
  }
  .site-nav ul a {
    text-decoration: none;
  }
}
@media screen and (max-width: 480px) {
  .content table td {
    min-width: auto;
  }
  .content img.left {
    float: none;
    margin-right: 0;
    width: 100%;
  }
  .content img.right {
    float: none;
    margin-left: 0;
    width: 100%;
  }
}
