@import "fonts";

@blue: #0000FF;
@blue-text: #0068B2;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  font-size: 10px;
  font-weight: 400;
  font-family: Montserrat, Arial, sans-serif;
  line-height: 1.2;
  color: #000;
  background-color: #fff;
}
a {
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
ol, ul {
  list-style-position: inside;
}

.wrap {
  width: 100%;
  max-width: 920px;
  margin: 0 auto;
  padding: 0 10px;
}

header {
  .header {
    &__mob {
      display: none;
      background-color: @blue;
      padding: 8px 15px;
      margin-bottom: 15px;
    }
    &__burger {
      position: relative;
      width: 25px;
      height: 16px;

      span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transition: all .3s ease;

        &:nth-child(2) {
          top: 7px;
        }
        &:nth-child(3) {
          top: 14px;
        }
      }
    }
    &__title {
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      line-height: 1;
    }
    &__banner {
      width: 100%;
      height: auto;
      margin-bottom: 15px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

main {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  min-height: ~"calc(100vh - 164px)";
}

article {
  flex: 1;
}

aside {
  width: 240px;
  margin: 0 20px 30px 0;

  .sidebar-block {
    margin-bottom: 15px;

    &__title {
      display: block;
      background-color: @blue;
      text-align: center;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      color: #fff;
      padding: 3px 5px;
      margin-bottom: 5px;
    }
    &__list {
      list-style: none;

      li {
        margin-bottom: 5px;

        &.current {
          a {
            text-decoration: none;
          }
        }
      }
      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.news-block {
  margin-bottom: 15px;

  &__caption {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    margin-bottom: 5px;
  }
  &__title {
    font-size: 12px;
    text-decoration: underline;
    color: @blue-text;
    
    &:hover {
      color: @blue-text;
    }
  }
  &__date {}
  &__text {}
}

.content {
  & > * {
    margin-bottom: 12px;
  }
  &__title,
  h1 {
    position: relative;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    background-color: @blue;
    padding: 2px 5px;

    span {
      position: relative;
      background-color: @blue;
      padding: 0 10px;
    }
    .home & {
      text-transform: uppercase;

      &:before {
        display: block;
        content: '';
        width: 94%;
        height: 2px;
        background-color: #fff;
        position: absolute;
        left: 3%;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  h2, h3 {
    color: @blue;
    text-align: center;
  }
  h2 {
    font-size: 12px;
  }
  h3 {
    font-size: 10px;
  }
  a {
    color: @blue-text;
    text-decoration: underline;
    
    &:visited,
    &:hover {
      text-decoration: none;
    }
  }
  ul, ol {
    padding-left: 10px;
  }
  blockquote {
    background-color: #E5E5E5;
    border-radius: 30px;
    text-align: center;
    font-weight: 500;
    font-style: italic;
    padding: 11px 25px;
  }
  table {
    max-width: 100%;
    border-collapse: collapse;
    margin: 0 auto 12px;

    td {
      min-width: 65px;
      text-align: center;
      font-weight: 500;
      border: 1px solid @blue;
      padding: 3px 20px;
    }
  }
  img {
    &.full {
      width: 100%;
    }
    &.left {
      float: left;
      margin-right: 15px;
    }
    &.right {
      float: right;
      margin-left: 15px;
    }
    &.center {
      display: block;
      max-width: 100%;
      margin: 0 auto 12px;
    }
  }
}

footer {
  .footer {
    &__copyright {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: #fff;
      padding: 10px;
      background-color: @blue;
    }
  }
}

@import "media";