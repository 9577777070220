@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserrat.woff2") format("woff2"),
  url("../fonts/montserrat.woff") format("woff"),
  url("../fonts/montserrat.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserratmedium.woff2") format("woff2"),
  url("../fonts/montserratmedium.woff") format("woff"),
  url("../fonts/montserratmedium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/montserratbold.woff2") format("woff2"),
  url("../fonts/montserratbold.woff") format("woff"),
  url("../fonts/montserratbold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}