@media screen and (max-width: 768px) {
  header,
  footer {
    &.wrap {
      padding: 0;
    }
  }

  header {
    .header {
      &__mob {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &__burger {
        &.active {
          span {
            &:nth-child(1) {
              transform: rotate(33deg);
              transform-origin: left;
            }
            &:nth-child(2) {
              width: 0;
            }
            &:nth-child(3) {
              transform: rotate(-33deg);
              transform-origin: left;
            }
          }
        }
      }
      &__banner {
        display: none;
      }
    }
  }

  main {
    flex-direction: column-reverse;
  }

  aside {
    width: 100%;
    margin: 0 0 20px;
    padding: 0;

    .sidebar-block {
      &__list {
        li {
          text-align: center;
        }
      }
    }
  }

  .content {
    &__title,
    h1 {
      background-color: transparent;
      color: @blue;

      span {
        background-color: transparent;
      }
      .home & {
        &:before {
          display: none;
        }
      }
    }
  }

  .site-nav {
    position: absolute;
    top: -20px;
    left: 45px;
    background-color: @blue;
    padding: 5px;
    max-width: 150px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.56);
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;

    &.active {
      top: 10px;
      opacity: 1;
      visibility: visible;
    }
    .sidebar-block__title {
      display: none;
    }
    ul {
      flex-direction: column;
      color: #fff;

      li {
        margin-bottom: 10px;
        text-align: left;
      }
      a {
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .content {
    table {
      td {
        min-width: auto;
      }
    }
    img {
      &.left {
        float: none;
        margin-right: 0;
        width: 100%;
      }
      &.right {
        float: none;
        margin-left: 0;
        width: 100%;
      }
    }
  }
}